import { SvgIconProps } from '@/types/helpers'

export default function Settings(props: SvgIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#A8B7DE"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.09401 3.53021C8.91177 4.07694 8.49579 4.50673 7.99228 4.78709C7.91396 4.83069 7.83643 4.87554 7.75971 4.9216C7.26502 5.21857 6.68416 5.36439 6.11888 5.24871L4.91711 5.00278C4.07372 4.8302 3.21453 5.21665 2.7841 5.96218L2.16311 7.03775C1.73268 7.78328 1.8276 8.72059 2.39876 9.36469L3.21379 10.2838C3.5957 10.7145 3.76076 11.2889 3.75164 11.8644C3.75021 11.9548 3.75021 12.0452 3.75164 12.1355C3.76075 12.7111 3.5957 13.2854 3.21379 13.7161L2.39876 14.6352C1.82761 15.2793 1.73268 16.2167 2.16311 16.9622L2.7841 18.0378C3.21453 18.7833 4.07372 19.1697 4.91711 18.9971L6.1188 18.7512C6.68409 18.6356 7.26495 18.7814 7.75965 19.0784C7.83639 19.1244 7.91394 19.1693 7.99228 19.2129C8.49579 19.4933 8.91177 19.9231 9.09401 20.4698L9.48156 21.6325C9.75379 22.4491 10.5181 23 11.3789 23H12.6209C13.4818 23 14.246 22.4491 14.5183 21.6325L14.9058 20.4698C15.0881 19.9231 15.504 19.4933 16.0075 19.2129C16.0859 19.1693 16.1634 19.1244 16.2402 19.0784C16.7349 18.7814 17.3157 18.6356 17.881 18.7512L19.0827 18.9971C19.926 19.1697 20.7852 18.7833 21.2157 18.0378L21.8367 16.9622C22.2671 16.2167 22.1722 15.2793 21.601 14.6352L20.786 13.7162C20.4041 13.2855 20.2391 12.7111 20.2482 12.1356C20.2496 12.0452 20.2496 11.9548 20.2482 11.8644C20.2391 11.2888 20.4041 10.7144 20.786 10.2838L21.601 9.36469C22.1722 8.72059 22.2671 7.78328 21.8367 7.03775L21.2157 5.96218C20.7852 5.21666 19.926 4.8302 19.0827 5.00279L17.8809 5.2487C17.3157 5.36438 16.7348 5.21857 16.2401 4.92159C16.1634 4.87554 16.0859 4.83069 16.0075 4.78709C15.504 4.50673 15.0881 4.07694 14.9058 3.53021L14.5183 2.36754C14.246 1.55086 13.4818 1 12.6209 1H11.3789C10.5181 1 9.75379 1.55086 9.48156 2.36754L9.09401 3.53021ZM15 12C15 13.6569 13.6568 15 12 15C10.3431 15 8.99997 13.6569 8.99997 12C8.99997 10.3431 10.3431 9 12 9C13.6568 9 15 10.3431 15 12Z"
        fill="currentFill"
      />
    </svg>
  )
}
