import Fire from '@/app/_components/Icon/Fire'
import Logout from '@/app/_components/Icon/Logout'
import ServerIcon from '@/app/_components/Icon/Server'
import Settings from '@/app/_components/Icon/Settings'
import { useI18n } from '@/locales/client'
import Image from 'next/image'
import Link from 'next/link'

interface IUser {
  name: string
  imageURL: string
  email: string
  isAdmin?: boolean
}

export default function HeaderUserMenu({ user }: { user: IUser }) {
  const { name, imageURL, email, isAdmin } = user
  const handleSignOut = () => {
    window.location.href = '/api/auth/logout'
  }
  const t = useI18n()

  return (
    <div className="absolute right-0 top-65 z-50 w-291 animate-slideDownAndFade rounded-xl bg-white shadow-card-mobile xl:shadow-card-web">
      <div className="flex items-center gap-8 p-20 pb-8">
        <div className={`size-39 overflow-hidden rounded-full`}>
          <Link href={'/dashboard/me'}>
            <Image
              className={'rounded-full'}
              src={imageURL}
              alt="img"
              width={39}
              height={39}
              objectFit="cover"
            />
          </Link>
        </div>
        <div>
          <div className="c-h7">{name}</div>
          <div className="c-b4 text-french-bleu">{email}</div>
        </div>
      </div>
      <div className="flex flex-col p-8">
        {isAdmin && (
          <div className="c-b3 rounded-15 px-12 py-8 hover:bg-french-bleu/10">
            <Link
              target="_blank"
              href="/admin"
              className="flex size-full items-center gap-8">
              <ServerIcon fill="[#3C3C3C]" width={16} height={16} /> Admin
            </Link>
          </div>
        )}
        <div className="c-b3 rounded-15 px-12 py-8 hover:bg-french-bleu/10">
          <Link
            href="/dashboard/me"
            className="flex size-full items-center gap-8">
            <Fire fill="[#3C3C3C]" width={16} height={16} />
            {t('common.myBoothLabel')}
          </Link>
        </div>
        <div className="c-b3 rounded-15 px-12 py-8 hover:bg-french-bleu/10">
          <Link
            href="/change-password"
            className="flex size-full items-center gap-8">
            <Settings fill="[#3C3C3C]" width={16} height={16} />
            {t('common.changePwd')}
          </Link>
        </div>
        <div className="mx-auto my-8 h-1 w-[calc(100%_-_24px)] bg-black/10" />
        <button
          type="button"
          className="c-b3 flex cursor-pointer items-center gap-8 rounded-15 px-12 py-8 text-left  hover:bg-french-bleu/10"
          onClick={handleSignOut}>
          <Logout fill="[#3C3C3C]" width={16} height={16} />
          {t('common.signOutLabel')}
        </button>
      </div>
    </div>
  )
}
