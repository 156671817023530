'use client'

import Image from 'next/image'
import BoothBuddies from './boothBuddies.svg'

export function BoothBuddiesLogo({
  width,
  height,
  scale,
}: {
  width: number
  height: number
  scale?: boolean
}) {
  return (
    <Image
      src={BoothBuddies}
      width={width}
      height={height}
      className={scale ? 'scale-[1.03]' : ''}
      alt="christmas-logo"
    />
  )
}
