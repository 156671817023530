import { Paginated } from '@/backend/models/Common'
import { z } from 'zod'

export const MessageSchema = z.object({
  id: z.string(),
  userId: z.string(),
  type: z.enum(['text', 'file', 'link', 'client-data']),
  displayName: z.string().nullish(),
  imageURL: z.string().nullish(),
  createdAt: z.date(),
  fileName: z.string().nullish(),
  fileType: z.string().nullish(),
  fileSize: z.number().nullish(),
  content: z.string(),
  lastReadId: z.string().nullish(),
  senderType: z
    .enum(['user', 'creator', 'admin', 'unknown', 'agent'])
    .nullish(),

  isSystemGenerated: z.boolean(),
  isRead: z.boolean().nullable(),
})

export const ClientDataSchema = z.object({
  name: z.string().min(1),
  product: z.string().min(1),
  date: z.coerce.date(),
  information: z.string().min(1),
  contact: z.string().min(1),
})

export type Message = z.infer<typeof MessageSchema>

export const PaginatedMessagesSchema = Paginated(z.array(MessageSchema))
export type PaginatedMessages = z.infer<typeof PaginatedMessagesSchema>
