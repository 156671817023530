import { SvgIconProps } from '@/types/helpers'

export default function Note(props: SvgIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#3366B6"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 4C5.44772 4 5 4.44772 5 5V19C5 19.5523 5.44772 20 6 20H18C18.5523 20 19 19.5523 19 19V12C19 11.4477 19.4477 11 20 11C20.5523 11 21 11.4477 21 12V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V5C3 3.34315 4.34315 2 6 2H12C12.5523 2 13 2.44772 13 3C13 3.55228 12.5523 4 12 4H6Z"
        fill={'currentFill'}
      />
      <path
        d="M19.4958 3.76777C18.5195 2.79146 16.9366 2.79146 15.9602 3.76777L15.2531 4.47488C15.0579 4.67014 15.0579 4.98672 15.2531 5.18198L18.7887 8.71752C18.9839 8.91278 19.3005 8.91278 19.4958 8.71752L20.2029 8.01041C21.1792 7.0341 21.1792 5.45119 20.2029 4.47488L19.4958 3.76777Z"
        fill={'currentFill'}
      />
      <path
        d="M13.8389 6.5962C13.6436 6.40094 13.327 6.40094 13.1318 6.5962L8.06419 11.6638C7.91119 11.8168 7.81194 12.0153 7.78134 12.2295L7.30994 15.5293C7.21566 16.1893 7.78134 16.755 8.44131 16.6607L11.7411 16.1893C11.9553 16.1587 12.1538 16.0594 12.3068 15.9064L17.3744 10.8388C17.5697 10.6436 17.5697 10.327 17.3744 10.1317L13.8389 6.5962Z"
        fill={'currentFill'}
      />
    </svg>
  )
}
