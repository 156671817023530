'use client'

import { Button } from '@/app/_primitives/Button'
import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogTrigger,
} from '@/app/_primitives/Dialog'
import { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

function PopupNotMobileGmsContent({ onClose }: { onClose: () => void }) {
  return (
    <div className=" rounded-20 xl:hidden">
      <div>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src="/gms_modal.png"
          alt="굿즈매니저는 PC에서만 이용이 가능해요."
        />
      </div>
      <div className="bg-white px-16 py-20 text-center">
        <b className="c-h6">모바일 지원 안내</b>
        <p className="c-b4 mt-10 text-[#a3a3a3]">
          굿즈매니저는 PC에서만 이용이 가능해요. <br />
          최적의 사용환경을 위해 <br />
          데스크톱을 이용해 주세요.
        </p>
        <Button
          type="button"
          size="sm"
          className="mt-21 w-full bg-french-bleu"
          onClick={onClose}>
          돌아가기
        </Button>
      </div>
    </div>
  )
}

export default function PopupNotMobileGms() {
  const [open, setState] = useState(false)
  const isXl = useMediaQuery({
    query: '(min-width: 1024px)',
  })

  const onTrigger = () => {
    setState((prev) => !prev)
  }

  return (
    <div className="contents xl:hidden">
      <Dialog open={open && !isXl} onOpenChange={onTrigger}>
        <DialogTrigger>
          <div className={`flex cursor-pointer items-center gap-x-3`}>
            {/* <I18nTranslatorClient text="common.gmsLabel" /> */}
            굿즈 관리
            <span className="flex h-18 w-35 items-center justify-center rounded-5 bg-strawberry-milk text-11 font-bold text-cherry">
              Beta
            </span>
          </div>
        </DialogTrigger>
        <DialogOverlay className="bg-black/40" />
        <DialogContent
          className={
            'w-269 overflow-hidden whitespace-nowrap rounded-20 border-none bg-transparent p-0'
          }>
          <PopupNotMobileGmsContent onClose={onTrigger} />
        </DialogContent>
      </Dialog>
    </div>
  )
}
