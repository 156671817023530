'use client'

import { createI18nClient } from 'next-international/client'
import { usePathname } from 'next/navigation'

export const {
  useI18n,
  useScopedI18n,
  I18nProviderClient,
  useCurrentLocale,
  useChangeLocale,
} = createI18nClient({
  ko: () => import('./ko'),
  ja: () => import('./ja'),
  en: () => import('./en'),
})

export const useRealPathname = () => {
  const locale = useCurrentLocale()
  const pathname = usePathname()

  return pathname.replace(`${locale}`, '').replace(/\/\//, '/')
}
