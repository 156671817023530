import Verified from '@/app/_components/Icon/Verified'
import { cn } from '@/app/utils'
import { getUserAvatarURL } from '@/libraries/avatar'
import Image from 'next/image'
import Link from 'next/link'
import China from '../china.svg'
import ETC from '../etc.svg'
import EU from '../eu.svg'
import Japan from '../japanese.svg'
import Korea from '../korean.svg'
import USA from '../usa.svg'

type userType = {
  id: string
  type: string
  displayName: string
  imageURL: string | null
  isVerified?: boolean
  isAgency?: boolean
  country?: string
}

const getCountryImageURL = (country?: string) => {
  if (country === 'kr') return Korea
  else if (country === 'jp') return Japan
  else if (country === 'cn') return China
  else if (country === 'us') return USA
  else if (country === 'eu') return EU
  else return ETC
}

export function Avatar({
  userInfo,
  size = 'small',
  className,
  href,
}: {
  userInfo: userType
  size?: 'small' | 'medium'
  className?: string
  href: string
}) {
  const isMedium = Boolean(size === 'medium')
  return (
    <Link
      href={href}
      className={cn(
        'relative c-b4 flex items-center gap-8 text-[#7C7C7C]',
        className,
      )}>
      <div
        className={cn(
          'relative size-32 shrink-0 overflow-hidden rounded-full',
          { 'size-40': isMedium },
        )}>
        <Image
          src={userInfo.imageURL || getUserAvatarURL(userInfo.id)}
          fill
          alt="category"
          className="object-cover"
        />
      </div>
      <div
        className={cn('group-hover:line-clamp-1 max-w-205 text-left', {
          'text-16': isMedium,
        })}>
        {userInfo.displayName}
      </div>
      {userInfo.isVerified && <Verified fill="#3366B6" />}
      {userInfo.isAgency && (
        <div
          className={cn(
            'flex h-17 w-45 items-center justify-center rounded-full bg-french-bleu !text-10 font-bold text-white',
            { 'right-20 !text-14 text-white font-bold !w-67 !h-24': isMedium },
          )}>
          Agency
        </div>
      )}
      {userInfo.isAgency && (
        <Image
          src={getCountryImageURL(userInfo.country)}
          className={cn('absolute bottom-0 z-[11] left-16', {
            'left-26': isMedium,
          })}
          alt={`${userInfo.country}`}
          width={20}
          height={20}
        />
      )}
    </Link>
  )
}
