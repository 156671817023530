import { SvgIconProps } from '@/types/helpers'

export default function ServerIcon(props: SvgIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V8C21 9.65685 19.6569 11 18 11H6C4.34315 11 3 9.65685 3 8V6ZM5.5 7C5.5 6.44772 5.94772 6 6.5 6H13.5C14.0523 6 14.5 6.44772 14.5 7C14.5 7.55228 14.0523 8 13.5 8H6.5C5.94772 8 5.5 7.55228 5.5 7ZM17.5 8C18.0523 8 18.5 7.55228 18.5 7C18.5 6.44772 18.0523 6 17.5 6C16.9477 6 16.5 6.44772 16.5 7C16.5 7.55228 16.9477 8 17.5 8Z"
        fill={props.fill || '#3366B6'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 16C3 14.3431 4.34315 13 6 13H18C19.6569 13 21 14.3431 21 16V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V16ZM5.5 17C5.5 16.4477 5.94772 16 6.5 16H13.5C14.0523 16 14.5 16.4477 14.5 17C14.5 17.5523 14.0523 18 13.5 18H6.5C5.94772 18 5.5 17.5523 5.5 17ZM17.5 18C18.0523 18 18.5 17.5523 18.5 17C18.5 16.4477 18.0523 16 17.5 16C16.9477 16 16.5 16.4477 16.5 17C16.5 17.5523 16.9477 18 17.5 18Z"
        fill={props.fill || '#3366B6'}
      />
    </svg>
  )
}
