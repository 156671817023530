import { SvgIconProps } from '@/types/helpers'

export default function Logout(props: SvgIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12 2C13.6569 2 15 3.34315 15 5V11H10C9.44772 11 9 11.4477 9 12C9 12.5523 9.44772 13 10 13H15V19C15 20.6569 13.6569 22 12 22H6C4.34315 22 3 20.6569 3 19V5C3 3.34315 4.34315 2 6 2H12Z"
        fill={props.fill || '#3366B6'}
      />
      <path
        d="M15 13L18.0858 13L16.2929 14.7929C15.9024 15.1834 15.9024 15.8166 16.2929 16.2071C16.6834 16.5976 17.3166 16.5976 17.7071 16.2071L20.5 13.4142C21.2811 12.6332 21.281 11.3668 20.5 10.5858L17.7071 7.79289C17.3166 7.40237 16.6834 7.40237 16.2929 7.79289C15.9024 8.18342 15.9024 8.81658 16.2929 9.20711L18.0858 11L15 11V13Z"
        fill={props.fill || '#3366B6'}
      />
    </svg>
  )
}
