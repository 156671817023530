'use client'

import { useI18n } from '@/locales/client'
import Link from 'next/link'

export default function FooterQuickLinks() {
  const t = useI18n()
  return (
    <div className="c-b3 flex flex-col gap-4 text-white/80">
      <Link href="/terms?search=privacy-policy">
        {t('common.personalInfoAgreeLink')}
      </Link>
      <Link href="/terms">{t('common.serviceAgreeLink')}</Link>
      <Link href="/update-notes">{t('common.updateNoteLabel')}</Link>
      {/* <Link href="/update-notes">블로그</Link> */}
    </div>
  )
}
