'use client'

import CustomToaster from '@/app/_components/CustomToaster'
import UserTypeChecker from '@/app/_components/ProfileChecker'
import StyledJsxRegistry from '@/app/registry'
import { I18nProviderClient } from '@/locales/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { Provider } from 'jotai'
import { NuqsAdapter } from 'nuqs/adapters/next/app'
import React, { useState } from 'react'
import './initialize'

export default function Providers({
  children,
  locale,
}: {
  locale: string
  children: React.ReactNode
}) {
  const [queryClient] = useState(() => new QueryClient())

  dayjs.locale(locale)

  return (
    <QueryClientProvider client={queryClient}>
      <I18nProviderClient locale={locale}>
        <Provider>
          <StyledJsxRegistry>
            <NuqsAdapter>{children}</NuqsAdapter>
          </StyledJsxRegistry>
        </Provider>
        <CustomToaster />
        <UserTypeChecker />
      </I18nProviderClient>
    </QueryClientProvider>
  )
}
