'use client'

import Image from 'next/image'
import ChristmasLogoImg from './christmas_logo.svg'
import stamplogo from './stamp-logo.png'

export function LogoImage({
  width,
  height,
  scale,
}: {
  width: number
  height: number
  scale?: boolean
}) {
  const now = new Date()
  const endAniversaryDate = new Date('2024-12-25T23:59:59')
  const isAniversaryLogoVisible = now <= endAniversaryDate
  return (
    <Image
      src={isAniversaryLogoVisible ? ChristmasLogoImg : stamplogo}
      width={width}
      height={height}
      className={scale ? 'scale-[1.03]' : ''}
      alt="footer-stamp-logo"
    />
  )
}
