'use client'

import HeaderUserMenu from '@/app/_components/Shared/HeaderAvatar/_components/HeaderUserMenu'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'

export default function HeaderAvatar({
  name,
  imageURL,
  email,
  isAdmin,
}: {
  imageURL: string
  name: string
  email: string
  isAdmin?: boolean
}) {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => setIsOpen((prev) => !prev)
  const ref = useRef<HTMLDivElement>(null)
  const pathname = usePathname()
  const userProps = {
    name,
    imageURL,
    email,
    isAdmin,
  }

  useEffect(() => {
    const handleClickOutside: EventListenerOrEventListenerObject = (
      e: Event,
    ) => {
      if (isOpen && ref.current && !ref.current.contains(e.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [isOpen])

  return (
    <div>
      <div className="relative flex cursor-pointer items-center" ref={ref}>
        <div role="presentation" className="flex items-center">
          <div
            aria-hidden="true"
            className={`flex size-39 items-center overflow-hidden rounded-full ${
              pathname.includes('/dashboard/me')
                ? 'border-2 border-white shadow-[0_0_0_2px_#fdcc53]'
                : ''
            }`}>
            <Link href={'/dashboard/me'}>
              <Image
                className={'rounded-full'}
                src={imageURL}
                width={39}
                height={39}
                alt="프로필 사진"
              />
            </Link>
          </div>
          <button
            type="button"
            className="relative size-24"
            onClick={handleOpen}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.11716 11.6087C9.27337 11.4638 9.52663 11.4638 9.68284 11.6087L12 13.758L14.3172 11.6087C14.4734 11.4638 14.7266 11.4638 14.8828 11.6087C15.0391 11.7536 15.0391 11.9885 14.8828 12.1334L12.5657 14.2827C12.2533 14.5724 11.7467 14.5724 11.4343 14.2827L9.11716 12.1334C8.96095 11.9885 8.96095 11.7536 9.11716 11.6087Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
        {isOpen && <HeaderUserMenu user={userProps} />}
      </div>
    </div>
  )
}
