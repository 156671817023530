import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Home/Characters/bg4_3xl_2xl.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Home/Characters/bg4_md_sm.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Home/Characters/bg4_xl_lg.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Home/Characters/Categories/_components/CategoryTitle1.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Home/Characters/Categories/_components/CategoryTitle2.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Home/Characters/Categories/all_category.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Home/Characters/flower_shape.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Home/Characters/Hot/_components/GeneralGrid.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Home/Characters/Hot/_components/HotGrid.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Home/Characters/pink_flower.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Home/Characters/star_shape.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Home/Characters/string_shape.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Home/Hero/_components/AskSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Home/Hero/bonnie.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Home/News/Guide/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Home/Register/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/inf-card-sm.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Licensing/Hero/_components/BooniePlayer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LogoImage"] */ "/vercel/path0/app/_components/LogoImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Section/FAQSection/FAQSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Section/NewsLetter/NewsLetterSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/I18nTranslator/I18nTranslatorClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/ably.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/bymono.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/casetify.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/crazymin.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/cu.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/folder.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/gosty.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/haru_film.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/hnf.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/kyobo.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/lespace.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/life4.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/loft.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/lotte_world.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/lotte.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/miniso.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/neople.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/nlize.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/olive0.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/photo_widget.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/Shared/Partners/starfield.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/shirts1.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_components/TextFields/SearchBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/vercel/path0/app/_primitives/Button.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.2_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/.pnpm/next@14.2.2_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
