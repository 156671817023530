import { SvgIconProps } from '@/types/helpers'

export default function InuptIcon(props: SvgIconProps) {
  return (
    <svg
      width="34"
      height="15"
      viewBox="0 0 34 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect width="34" height="14.0435" rx="7.02174" fill="#1A1A1A" />
      <path
        d="M6.14904 10.5217H4.13861V3.17774H6.14904V10.5217ZM12.669 10.5217H10.7295L9.20396 7.104H9.10935V10.5217H7.31179V3.17774H9.3104L10.765 6.70192H10.8596V3.17774H12.669V10.5217ZM15.7795 8.10922C15.7795 8.35363 15.8307 8.53496 15.9332 8.65322C16.0357 8.77148 16.2012 8.83061 16.4299 8.83061C16.6664 8.83061 16.828 8.77542 16.9148 8.66505C17.0094 8.54679 17.0567 8.35757 17.0567 8.0974V3.17774H19.0908V8.21566C19.0908 9.05925 18.8582 9.67421 18.393 10.0605C17.9357 10.4468 17.2577 10.64 16.3589 10.64C15.468 10.64 14.8018 10.4508 14.3603 10.0724C13.9267 9.68603 13.7099 9.07502 13.7099 8.23931V3.17774H15.7795V8.10922ZM22.6619 8.12105H22.1179V10.5217H20.1311V3.17774H22.5318C23.3754 3.17774 24.0022 3.3709 24.4121 3.75722C24.8221 4.14354 25.0271 4.77821 25.0271 5.66122C25.0271 6.54424 24.8339 7.17496 24.4476 7.5534C24.0613 7.93183 23.466 8.12105 22.6619 8.12105ZM22.8866 6.52453C22.9575 6.3905 22.993 6.15003 22.993 5.80313C22.993 5.45624 22.9536 5.21183 22.8747 5.06992C22.7959 4.928 22.6382 4.85705 22.4017 4.85705H22.1179V6.72557H22.449C22.6697 6.72557 22.8156 6.65856 22.8866 6.52453ZM29.886 4.96348H28.5142V10.5217H26.4919V4.96348H25.0373L25.1556 3.17774H30.0043L29.886 4.96348Z"
        fill="white"
      />
    </svg>
  )
}
