import { SvgIconProps } from '@/types/helpers'

export default function ChevronDown(props: SvgIconProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="#8B8B8B"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.29289 8.54289C5.68342 8.15237 6.31658 8.15237 6.70711 8.54289L12.5 14.3358L18.2929 8.54289C18.6834 8.15237 19.3166 8.15237 19.7071 8.54289C20.0976 8.93341 20.0976 9.56658 19.7071 9.9571L13.9142 15.75C13.1332 16.531 11.8668 16.531 11.0858 15.75L5.29289 9.95711C4.90237 9.56658 4.90237 8.93342 5.29289 8.54289Z"
        fill="currentFill"
      />
    </svg>
  )
}
