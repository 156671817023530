import { SvgIconProps } from '@/types/helpers'

export default function Fire(props: SvgIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12 22C16.2526 22 20 19 20 15C20 11 17 9 16 8C14.5939 6.5939 14.1521 4.74612 14.0254 3.4746C13.9552 2.77067 13.2759 2.27145 12.6864 2.66243C11.5335 3.42702 9.90375 4.98749 9 8C8.35747 10.1418 9 12 9 12C9 12 8 11.5 7.5 10C7.3353 9.5059 7.11635 8.90329 6.89675 8.31727C6.58071 7.47387 5.44066 7.48134 5.1629 8.33811C4.61497 10.0282 4 12.3031 4 14C4 19 7.74745 22 12 22Z"
        fill={props.fill || '#3366B6'}
      />
    </svg>
  )
}
